iframe {
    display: none;
}

.a-enter-vr-button {
    bottom: auto !important;
    top: 40px;
}

.loading-nfts {
    text-shadow: 2px 2px 4px #000;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

loading-rotate-screen .a-loader-title {
    text-shadow: 2px 2px 4px #000;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #fff;
}

@keyframes gradient-animation {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}

.loading-nfts {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: linear-gradient(0deg, #3730A3, #1E2A3B, #0033E9);
    background-size: 200% 200%;
    color: #fff;
    text-align: center;
    animation: falling-gradient 3s linear infinite alternate;
    overflow-y: auto;
}

.landing-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: linear-gradient(0deg, #3730A3, #1E2A3B, #0033E9);
    background-size: 200% 200%;
    color: #fff;
    text-align: center;
    animation: falling-gradient 10s linear infinite alternate;
    overflow-y: auto;
}

.loading-rotate-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: linear-gradient(90deg, #3730A3, #1E2A3B, #0033E9);
    background-size: 200% 200%;
    color: #fff;
    text-align: center;
    animation: falling-gradient 3s linear infinite alternate;
}

@keyframes falling-gradient {
    0% {
        background-position: 100% 0;
    }

    100% {
        background-position: 0 100%;
    }
}


.rotate-device {
    display: none;
    /* Initially hidden */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    font-size: 20px;
    z-index: 1000;
    padding-top: 30vh;
    /* Center the content vertically */
}

/* Show the rotation prompt in portrait mode */
@media (orientation: portrait) {
    .rotate-device {
        display: block;
    }
}

/* Common styles for both joysticks */
.joystick {
    position: fixed;
    /* Fixed position */
    bottom: 20px;
    /* Place at bottom */
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.8);
    /* You can set them to display: block; if you want them to be visible all the time */
}

/* Specific style for the left joystick */
.left-joystick {
    left: 20px;
    /* Place on left side */
    border-radius: 50%;
    /* Rounded shape */
}

/* Specific style for the right joystick */
.right-joystick {
    right: 20px;
    /* Place on right side */
    background-color: transparent;
    /* No background */
    border: 5px solid rgba(255, 255, 255, 0.8);
    /* Cross made of borders */
    box-sizing: border-box;
    /* To include the border in the element's total width and height */
}

/* Horizontal and vertical lines for the right joystick to make it cross-shaped */
.right-joystick:before,
.right-joystick:after {
    content: "";
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
}

.right-joystick:before {
    top: 0;
    bottom: 0;
    left: 50%;
    width: 5px;
    margin-left: -2.5px;
}

.right-joystick:after {
    left: 0;
    right: 0;
    top: 50%;
    height: 5px;
    margin-top: -2.5px;
}

.scrollable-container {
    max-height: 600px;
}

/* Styles for Table */
.table-container {}

.scrollable-container,
.table-container {
    box-sizing: border-box;
}

table {
    width: 100%;
    border-collapse: collapse;
    background: #4EA4FF;
}

thead th {
    background: #0033E9;
    text-align: center;
    padding: 10px;
}

tbody td {
    padding: 10px;
}

tbody tr:nth-child(even) {
    background: #3B6BDA;
}

/* Styles for Filter Input */
.filter-inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    /* Horizontally center items */
}

.filter-input {
    min-width: 100px;
    max-width: 200px;
    /* or whatever max-width you prefer */
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 1;
}

/* For mobile, add horizontal scroll */
@media (max-width: 768px) {
    .table-container {
        overflow-x: scroll;
    }

    .filter-inputs {
        flex-wrap: wrap;
    }
}

.cronos-address {
    font-size: small;
}

/* Change the style of buttons */
button {
    background-color: #0033E9;
    color: white;
    padding: 5px 20px;
    border: none;
    cursor: pointer;
    margin-left: 15px;
    /* Horizontal space to the left */
    margin-right: 15px;
    /* Horizontal space to the right */
}

/* Change the style of text input and select */
input,
select {
    color: white;
    background-color: #3B6BDA;
    padding: 14px 20px;
    margin-top: 10px;
    /* Vertical space above the element */
    margin-bottom: 10px;
    /* Vertical space below the element */
    margin-left: 15px;
    /* Horizontal space to the left */
    margin-right: 15px;
    /* Horizontal space to the right */
    border: none;
    cursor: pointer;
}

::placeholder {
    color: white;
}

.logo-cronos {
    width: 300px;
    margin: 50px;
    /* Add any other styles here */
}

.logo-project {
    height: 150px;
    margin: 10px;
    /* Add any other styles here */
}

/* Style for unordered lists */
ul {
    list-style-type: none;
    /* Remove default bullets */
    padding-left: 0;
    /* Remove padding */
    margin-bottom: 20px;
    /* Add some bottom margin for separation */
}

/* Style for list items */
ul li {
    background-color: rgba(242, 151, 39, 0.2);
    /* Light background for visibility */
    padding: 10px;
    /* Padding for each item */
    margin: 5px 0;
    /* Vertical margin */
    border-radius: 5px;
    /* Rounded corners */
    transition: background-color 0.3s ease;
    /* Transition for hover effect */
}

/* Hover effect for list items */
ul li:hover {
    background-color: rgba(242, 151, 39, 0.4);
    /* Darker background on hover */
}

a {
    color: white;
    text-decoration-style: solid;
}

a:hover {
    font-weight: bold;
}

.arrow {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 10px;
    border-style: solid;
}

.accordion-header {
    background-color: #0033E9;
    color: white;
    padding: 10px;
    cursor: pointer;
    text-align: center;
}

.accordion-header:hover {
    font-weight: bold;
}

.accordion-content {
    background-color: #3B6BDA;
    padding: 10px;
}

/* CookieConsentStyles.css */

.custom-cookie-consent {
    background: darkblue;
    z-index: 999999 !important;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.custom-cookie-button {
    background: lightgreen;
    color: black;
    padding: 10px 20px;
    font-size: 16px;
}